import analysisCard from "../ToolsCard.vue";

import Button from "@/components/button";
import Input from "@/components/input";
import Loader from "@/components/loader";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "../../../../axios/osint.js";
import SaasAxios from "@/axios/index.js";
import mainAxios from "@/axios";
import {setUsernamesDropdownData, convertToCSV, csvFileName, getMappingValue, getMonitoring} from "@/utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import JsonCSV from "vue-json-csv";
import {OFFSHORELEAKS_TOOL_MAPPING} from "@/utils/constants";
import NeoPagination from "@/components/pagination";
import {cloneDeep, flatten, uniq, uniqBy, groupBy, get, isEqual} from "lodash";
import VueMultiselect from "@/components/vue-multiselect";
import toggleSwitch from "@/components/toggle-switch";
import ModalConfirm from "@/components/modal-confirm";
import ResultList from "@/components/result-list";
import ResultExpanded from "@/components/result-expanded";
import uploadButton from "@/components/upload-button";
import AdverseMediaResources from "@/views/research/tools/adverse-media/adverse-media-resources";
import {EventBus} from "@/main.js";
import {monitoringSearchData, monitoringHistoryData} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import runAllQueries from "@/mixins/runAllQueries";
import {DateTime} from "luxon";
import neoSwitch from "@/components/toggle-switch";
import ExpandFilter from "./../ExpandFilter.vue";
import FilterModal from "./../FilterModal.vue";
const generateFiltersWrapperModel = (title, src_name, value, filters, tagClass, disabled) => ({
    title,
    src_name,
    value,
    filters,
    tagClass,
    isChecked: false,
    isModalVisible: false,
    disabled,
});

export default {
    name: "neo-offshoreleaks",
    /**
     * @mixin
     * @name toolsHelper - mixin to control tools & monitoring actions
     */
    mixins: [toolsHelper, runAllQueries],
    /**
     * @components - external components used in the component
     */
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-loader": Loader,
        "analysis-card": analysisCard,
        "download-csv": JsonCSV,
        "modal-confirm": ModalConfirm,
        NeoPagination,
        VueMultiselect,
        toggleSwitch,
        ResultList,
        ResultExpanded,
        "neo-upload-button": uploadButton,
        AdverseMediaResources,
        ExpandFilter,
        FilterModal,
        neoSwitch,
        // MonitoringFilter
    },
    props: {
        /**
         * @property
         * @name toolData - input data (case data + inputs run throught tools) for input types matching to selected tool
         */
        toolData: {
            type: Array,
            default: () => [],
        },
        /**
         * @property
         * @name tool - selected tool name
         */
        tool: {
            type: String,
            default: "",
        },
        isQueryPanelExpanded: {
            type: Boolean,
            default: false,
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        toolSearchQueries: Array,
        frequency: {
            default: "",
        },
    },
    data: () => ({
        searchItem: "",
        nameSearches: [],
        loaderController: {
            nameSearch: {
                loading: false,
                success: false,
                filterLoading: false,
                filterSuccess: false,
            },
        },
        adverseResults: [],
        adverseFilteredResults: [],
        showResult: false,
        showFiltersPanel: false,
        currentQuery: -1,
        queries: [],
        newEntity: null,
        entityCount: 1,
        selectedItem: [],
        triggerRoute: true,
        selectedResultCard: null,
        isOldVersionSelected: false,
        isResultExpanded: false,
        expandedResultCard: null,
        page_number: 1,
        items_count: 10,
        searchQuery: "",
        sourceType: [],
        filterEntityType: [],
        filterTypes: [],
        filterJurisdiction: [],
        selectVal: [""],
        searchedClicked: false,
        tabName: "",
        allFilters: [],
        peps: true,
        sanction: true,
        adverseMedia: true,
        searchKeyword: "",
        sources: [],
        bulkMonitor: false,
        open: false,
        monitoringStatus: false,
        selected: "",
        inputlength: 0,
        /**
         * @property
         * @name allSources - sources data along with sources filters and title
         *                  - each source data returned through a function generateFiltersWrapperModel
         *                      - containing source title, source name mapping, source value mapping, and filters allowed
         */
        allSources: {
            offshoreLeaks: generateFiltersWrapperModel("Offshore Leaks", "", "offshoreleaks", ["searchMode"], "tw-bg-yellow-100"),
        },
        /**
         * @property
         * @name entityType - entityType filter data
         */
        entityType: {
            label: "Entity type",
            value: {
                name: "All",
                value: "",
            },
            props: {
                options: [],
                preselectFirst: true,
                placeholder: "Select Entity Type",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
                checkbox: true,
                allowEmpty: false,
                trackBy: "name",
                label: "name",
                checkboxes: true,
                edit: false,
            },
            isSourceSpecific: false,
        },
        /**
         * @property
         * @name searchMode - searchMode filter data
         */
        searchMode: {
            label: "Search Mode",
            value: {
                name: "Exact Match",
                value: "exact_match",
            },
            props: {
                options: [
                    {
                        name: "Exact Match",
                        value: "exact_match",
                    },
                    {
                        name: "Part Match",
                        value: "part_match",
                    },
                ],
                // maxHeight: 250,
                placeholder: "Select Search Mode",
                tagPlaceholder: "",
                closeOnSelect: true,
                showLabels: false,
                allowEmpty: false,
                checkboxes: true,
                trackBy: "value",
                label: "name",
            },
            isSourceSpecific: true,
        },
        icij_entity_type: [],
        isSavedLoading: {
            loading: false,
            loadingCardIndex: null,
        },
        isBulkMonntoringLoading: false,
        savedFilter: "all",
        request_id: null,
        isExpandedLoaded: false,
        offshore_entities: [],
        perPageOptions: [10, 20, 50, 100],
        order_by: {
            name: "Updated Date",
            value: "updated_at",
        },
        sortKeysMapping: {
            created_at: {
                complyAdvantage: "created_utc",
                worldCheck: "entered",
                DowJones: "created_at",
                offshoreLeaks: "",
            },
            updated_at: {
                complyAdvantage: "last_updated_utc",
                worldCheck: "updated",
                DowJones: "updated_at",
                offshoreLeaks: "",
            },
        },
        appliedFilterCount: 0,
        filters: {
            saved: "all",
            monitoring: "all",
        },
        filteredSources: {},
        prevAppliedFilters: {
            monitoring: "all",
            saved: "all",
            sourceType: [],
            searchQuery: "",
            filterEntityType: [],
            filterTypes: [],
            filterJurisdiction: [],
        },
        isBulkMonitorOn: false,
        latestVersionTotalResults: 0,
        timeline: [],
        offset: 0,
        isFilterApplied: false,
        case_id: "",
        isComparisonModeOn: false,
        searchInput: "",
    }),
    computed: {
        ...mapGetters(["getReadOnlyMode", "getCaseId", "getProduct", "getResearchData", "getAllToolsData", "getActiveCaseId", "getCaseNames", "getSocialPlatforms", "getPermissions", "checkPermissionsFetched", "getSelectedToolQuery", "getMonitoringDateFilter", "getAllMonitoringData", "getToolsFilterData", "nerFilters"]),

        searchQueryExists() {
            let flag = true;
            if (this.toolSearchQueries.findIndex((el) => el._id === this.getSelectedToolQuery?.query_id) < 0) flag = false;
            return flag;
        },

        sortOptions() {
            if (this.order_by?.value == "updated_at") {
                return [
                    {
                        name: "Updated Date",
                        value: "updated_at",
                    },
                    {
                        name: "Created Date",
                        value: "created_at",
                    },
                ];
            }
            if (this.order_by.value == "created_at") {
                {
                    return [
                        {
                            name: "Created Date",
                            value: "created_at",
                        },
                        {
                            name: "Updated Date",
                            value: "updated_at",
                        },
                    ];
                }
            }
        },

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },
        socialProfiles() {
            return this.getSocialPlatforms.filter((n) => n.key === "linkedin" || n.key === "twitter" || n.key === "github" || n.key === "gitlab" || n.key === "instagram" || n.key === "youtube");
        },
        /**
         * @property
         * @name csvData
         * @return {Array} - return search results data to be downloaded in csv
         */
        csvData() {
            return this.convertToCSV(this.adverseResultsList);
        },

        /**
         * @property
         * @name csvFields
         * @return {Array} - return list of fields to be downloaded in csv
         */
        csvFields() {
            return ["name", "aka_names", "types", "entity_type", "dob", "locations"];
        },
        csvLabels() {
            return {name: "Name", aka_names: "Aka Names", types: "Types", entity_type: "Entity Type", dob: "DOB", locations: "Locations"};
        },

        uploadUrl() {
            return `osint-tools/search/bulk-upload`;
        },

        /**
         * @property
         * @name expandedCard
         * @return {Object} - return data for the selected card
         */
        expandedCard() {
            return this.adverseFilteredResults[this.selectedResultCard]?.api_all_data ?? {};
        },
        /**
         * @property
         * @name adverseResultsList
         * @return {Array} - return list of all search results after applying filters
         */
        adverseResultsList() {
            let data = cloneDeep(this.adverseFilteredResults);
            // if (this.$route.name === "monitoring" && this.filterNewResults) {
            //     data = data.filter((res) => res?.api_all_data?.hasChanges);
            //     console.log(data);
            // }

            return data;
        },
        /**
         * @property
         * @name getTotalPages
         * @return {Number} - return number of total pages of search results
         */
        getTotalPages() {
            return Math.ceil(this.adverseResultsList.length / this.items_count);
        },

        /**
         * @property
         * @name isTypeFilterDisabled
         * @return {Boolean} - check if type outupt filter should be enabled or not
         */

        isTypeFilterDisabled() {
            return !this.allSources["offshoreLeaks"]?.isChecked;
        },

        /**
         * @property
         * @name getSourcesList
         * @return {Array} - return list of sources (output filters)
         */
        getSourcesList() {
            let data = [];
            let sources = [];
            this.adverseResults?.forEach((src) => {
                let match = -1;
                if (src?.key && !sources.includes(src?.key)) {
                    sources.push(src?.key);
                    data.push({
                        name: src?.key,
                    });
                }
            });

            return [{group: "Source", categories: data}];
        },

        inputInformation() {
            let info =
                '<ul style="list-style: none;"><strong>Using Chips for Multiple Values</strong>' +
                "<li>1. <strong>Enter:</strong> Type a value in the input field and press enter or  press comma to create a chip.</li>" +
                "<li>2. <strong>Add More:</strong> Repeat for additional values.</li>" +
                '<li>3. <strong>Remove:</strong> Click the "x" on a chip to delete a value.</li>' +
                "<li>4. <strong>Edit:</strong> Select a value in the chip or modify text to modify its value.</li>" +
                "<li>5. <strong>Bulk Enter:</strong> Copy text from Excel or line by line from a text file and paste it into the field will convert the line items to chips.</li></ul>";
            return info;
        },

        /**
         * @property
         * @name getEntityTypes
         * @return {Array} - return list of entity types (output filters)
         */
        getEntityTypes() {
            let results = [];
            if (this.icij_entity_type.length) {
                this.icij_entity_type.map((e) => {
                    results.push({
                        value: e.toLowerCase(),
                        source: "offshoreLeaks",
                    });
                });
            } else if (this.adverseResults.length) {
                this.adverseResults?.map((e) => {
                    if (e?.entity_type && !results.some((res) => res.value === e?.entity_type?.toLowerCase()))
                        results.push({
                            value: e?.entity_type?.toLowerCase(),
                            source: "offshoreLeaks",
                        });
                });
            } else if (this.offshore_entities.length) {
                this.offshore_entities?.map((e) => {
                    if (e?.entity_type && !results.some((res) => res.value === e?.entity_type?.toLowerCase()))
                        results.push({
                            value: e?.entity_type?.toLowerCase(),
                            source: "offshoreLeaks",
                        });
                });
            }
            return [{group: "Entity Type", categories: results}];
        },

        // /**
        //  * @property
        //  * @name getFilterTypes
        //  * @return {Array} - return list of types (output filters)
        //  */
        // getFilterTypes() {
        //     let data = [];
        //     this.adverseResults?.forEach((adver) => {
        //         let advfilters = [];
        //         let adverseMediaCount = 0;
        //         let adversePepCount = 0;
        //         let pepCount = 0;
        //         let sanctionCount = 0;
        //         let adv = adver.types.split(",").forEach((adv) => {
        //             if (!adv.includes("adverse") && !adv.includes("pep") && !adv.includes("PEP ") && !adv.includes("sanction") && !adv.includes("Sanctions")) {
        //                 advfilters.push(adv);
        //             } else if (adv.includes("adverse")) {
        //                 adverseMediaCount++;
        //             } else if (adv.includes("pep")) {
        //                 adversePepCount++;
        //             } else if (adv.includes("PEP")) {
        //                 pepCount++;
        //             } else if (adv.includes("sanction") || adv.includes("Sanctions")) {
        //                 sanctionCount++;
        //             }
        //         });
        //         if (adverseMediaCount > 0) {
        //             advfilters.push("adverse-media");
        //         }
        //         data.push(
        //             ...advfilters.map((el) => ({
        //                 label: el.replace(/-/g, " ")?.replace(/None/g, "Others"),
        //                 value: el,
        //             }))
        //         );
        //         if (adversePepCount > 0) {
        //             data.push({
        //                 label: "PEP Listing",
        //                 value: "pep",
        //             });
        //         }
        //         if (pepCount > 0) {
        //             data.push({
        //                 label: "PEP/Warnings Details",
        //                 value: "PEP ",
        //             });
        //         }
        //         if (sanctionCount > 0) {
        //             data.push({
        //                 label: "Sanctions",
        //                 value: "sanction",
        //             });
        //         }
        //     });
        //     return [{ group: "Entity Type", categories: uniqBy(data, "label").filter((el) => el.label) }];
        // },

        /**
         * @property
         * @name getFilterJurisdiction
         * @return {Array} - return list of jurisdiction (output filters)
         */

        getFilterJurisdiction() {
            let countries = new Set();
            this.adverseResults?.forEach((result) => {
                if (result.key === "offshoreLeaks") {
                    countries.add(...result.jurisdiction?.split(",")?.map((el) => el.toLowerCase()));
                } else {
                    result?.api_all_data?.doc?.fields?.forEach((field) => {
                        if (field.name.toLowerCase() === "countries" || field.name.toLowerCase() === "country") {
                            countries.add(...field.value.split(",").map((el) => el.toLowerCase()));
                            return true;
                        } else return false;
                    });
                }
            });
            // || field.toLowerCase() === 'countries' ||field.toLowerCase() === 'country'
            let data = Array.from(countries);

            return [{group: "Jurisdiction", categories: data.sort((a, b) => a.localeCompare(b))?.filter((el) => el)}];
        },

        /**
         * @property
         * @name isMonitoringTab
         * @return {Boolean} - check if monitoring tab is selected
         */

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },

        /**
         * @property
         * @name checkMarkAllDone
         * @return {Boolean} - Monitoring - check if all results have been marked done or not
         */
        checkMarkAllDone() {
            let searchKeyword = this.searchQuery;
            let result = [];
            let adver_result = [];
            result = this.adverseFilteredResults;
            adver_result = this.adverseResultsList;
            result = result.slice(this.items_count * (this.page_number - 1), this.page_number * this.items_count);
            if (result && result.length) {
                return result.filter((res) => res?.name?.toLowerCase().includes(searchKeyword)).every((res) => res.api_all_data.changes_viewed);
            } else {
                return false;
            }
            this.$forceUpdate();
        },

        /**
         * @property
         * @name checkBulkToggleOn
         * @return {Boolean} - Monitoring - check if all results have toggle monitoring on or off
         */

        checkBulkToggleOn() {
            let searchedResultList = [];
            searchedResultList = this.adverseResultsList;
            if (searchedResultList && searchedResultList.length) {
                this.isBulkMonitorOn = searchedResultList.every((result) => result.api_all_data.monitoring_status);
                return searchedResultList.every((result) => result.api_all_data.monitoring_status);
            } else return false;
        },

        /**
         * @property
         * @name isSearchBtnDisabled
         * @return {Boolean} - check if search button is disabled (if any source is not selected)
         */
        isSearchBtnDisabled() {
            let isCheckedFilterExists = false;
            for (const filter in this.allSources) {
                if (this.allSources[filter].isChecked && this.selectedItem.length) {
                    isCheckedFilterExists = true;
                    break;
                }
            }
            return !isCheckedFilterExists;
        },

        allSourcesChecked() {
            let allSourcesChecked = true;
            for (let source in this.allSources) {
                if (!this.allSources[source].isChecked) {
                    allSourcesChecked = false;
                    break;
                }
            }
            return allSourcesChecked;
        },

        last_run_date() {
            return this.getSelectedToolQuery?.query?.last_runtime;
        },
        isEnabledApplyButton() {
            let {monitoring, saved} = this.filters;
            let appliedFilters = {
                monitoring,
                saved,
                sourceType: this.sourceType,
                searchQuery: this.searchQuery,
                filterEntityType: this.filterEntityType,
                filterTypes: this.filterTypes,
                filterJurisdiction: this.filterJurisdiction,
            };
            return isEqual(appliedFilters, this.prevAppliedFilters);
        },
    },
    watch: {
        showResult() {
            this.$el.scroll(0, 0);
        },
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
                this.currentQuery = -1;
            }
        },
    },
    created() {
        EventBus.$on("handleDone", (data) => {
            let result = cloneDeep(this.adverseResults);
            if (!data.oldVersion) {
                let index = result.findIndex((el) => el.curr_index === data.curr_index);
                if (index > -1) {
                    result[index] = data.card;
                    this.adverseResults = result;
                    // this.checkMarkAllDone()
                }
            } else {
                let oldVersionResults = cloneDeep(this.timeline);
                let index = oldVersionResults.findIndex((el) => el.curr_index === data.curr_index);
                if (index > -1) {
                    oldVersionResults[index] = data.card;
                    this.timeline = oldVersionResults;
                    // this.checkMarkAllDone()
                }
            }
            this.filterData();
            this.$emit("refreshQueries");
            this.$forceUpdate();
        });
        EventBus.$on("handleResultSave", (data) => {
            // API Call to save card
            this.handleSavedCard(data);
        });

        EventBus.$on("showMoreData", (data) => {
            this.expandResult(data);
        });
    },
    async mounted() {
        /**
         * map filters list and default selected data
         */
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        this.entityType.props.options = this.entityTypeOptions;
        await this.fetchAllJuridictions();
        for (let source in this.allSources) {
            this.allSources[source].filters = this.allSources[source].filters.map((filter) => this[filter]);
        }
        const dataRes = this.getToolsData(this.tabName, "offshoreleaks-tool");
        if (dataRes) this.redirectToQuery(dataRes);

        // this.setToolsFilterData(this.filters);
        // const { monitoring, saved, sources, entity_type, types, jurisdiction } = this.filters;
        // if (monitoring !== "all" || saved !== "all" || sources.length > 0 || entity_type.length > 0 || types.length > 0 || jurisdiction.length > 0) {
        // await this.getToolsStatus();
        // }

        await this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "offshoreleaks-tool");
        EventBus.$off("handleDone");
        EventBus.$off("handleResultSave");
        EventBus.$off("showMoreData");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_MONITORING_DATE_FILTERS", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseNames", "setToolsFilterData", "getToolsStatus"]),
        getMappingValue,
        getMonitoring,

        async getToolData() {
            const names = await this.getDropdownData("name");
            const companies = await this.getDropdownData("company_name");
            this.nameSearches = [{group: "Entities", categories: names.concat(companies)}];
        },
        // fetchSource(key) {},
        onToggle() {
            this.open = !this.open;
        },
        handleOutside() {
            this.open = false;
        },

        async handleSavedCard(cardData) {
            this.isSavedLoading = {
                loading: true,
                loadingCardIndex: cardData.api_all_data.doc_id,
            };
            let sources = [];
            sources.push(cardData.key.toLowerCase());
            let payload = {
                sources: sources,
                doc_id: cardData.api_all_data?.doc_id,
                saved: !cardData.api_all_data.saved,
                card_data: {
                    created_at: cardData?.created_at ?? null,
                    updated_at: cardData?.updated_at ?? null,
                    case_id: this.case_id,
                    query_id: this.request_id,
                    tool: "tool-offshoreleaks-meta",
                    tool_name: this.$route.params.toolName,
                },
            };
            let url = `/osint-tools/card/mark-as-saved`;
            try {
                const {data} = await mainAxios.post(url, payload);
                if (data.success === true) {
                    let result = cloneDeep(this.adverseResults);
                    let elementIndex = result.findIndex((obj) => obj.api_all_data.doc_id === cardData.api_all_data.doc_id);
                    let resultSavedElement = result[elementIndex];
                    let expandedResult = this.expandedResultCard;
                    resultSavedElement.api_all_data.saved = !resultSavedElement.api_all_data.saved;
                    if (expandedResult && expandedResult.api_all_data.doc_id === cardData.api_all_data.doc_id) {
                        expandedResult.api_all_data.saved = !expandedResult.api_all_data.saved;
                        this.expandedResultCard = expandedResult;
                    }
                    result.splice(elementIndex, 1, resultSavedElement);
                    this.adverseResults = result;
                    this.isSavedLoading = {
                        loading: false,
                        loadingCardIndex: null,
                    };
                    this.$toast.success("Updated!");
                    this.filterData();
                    this.$emit("refreshQueries");
                    this.$forceUpdate();
                }
            } catch {
                this.$toast.warning("Please try again after sometime!");
                this.isSavedLoading = {
                    loading: false,
                    loadingCardIndex: null,
                };
            }
        },
        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "offshoreleaks", [...this.nameSearches[0]?.categories], data);
            allChecked = [...this.nameSearches[0]?.categories]?.length ? ([...this.nameSearches[0]?.categories].findIndex((n) => !n.visited) > -1 ? false : true) : false;
            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        resetType() {
            if (this.sourceType?.name === "offshoreLeaks") this.filterTypes = "";
        },

        setInput(event) {
            if (this.selectedItem.length <= 0) {
                this.searchKeyword = event.value ?? "";
            } else {
                this.searchKeyword = this.selectedItem[0].value ?? "";
            }
        },

        customInput(event) {
            const searchName = this.nameSearches[0]?.categories?.findIndex((el) => el.value == event.value && el.value !== event.name && (el.visited || el.visited === false));
            if (searchName > -1) {
                // const namesearch = this.nameSearches;
                // namesearch[0].categories[searchName].value = event.name;
                let index = this.selectedItem.findIndex((el) => el.name === event.name);
                if (index > -1) this.selectedItem.splice(index, 1);
                this.addTag(event.value, index);
                this.setInput(event.value);
                this.$nextTick(() => {
                    const namesearch = this.nameSearches;
                    namesearch[0].categories[searchName].value = event.name;
                });
            }
            const search = this.nameSearches[0]?.categories?.findIndex((el) => el.name === event.name && el.code);
            if (search > -1) {
                this.nameSearches[0]?.categories.splice(search, 1);
                this.addTag(event.value, index);
                this.setInput(event.value);
            }
            if (event.value.length === 0) {
                let selectedIndex = this.selectedItem.findIndex((el) => el.value.length === 0);
                if (selectedIndex > -1) this.selectedItem.splice(selectedIndex, 1);
            }
        },

        deleteSearch(event) {
            let index = this.selectedItem.findIndex((el) => el.value === event);
            if (index > -1) this.selectedItem.splice(index, 1);
        },
        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
        },

        limitText(length) {
            // this.$emit('limitText', length);
            return ` +${length}`;
        },

        /**
         * @function
         * @name expandResult
         * @params {result, index, i} - result - selected card data
         *                            - index - selected card index
         */
        async expandResult(result) {
            const index = result.index;
            const isOldVersion = result.isOldVersion;
            this.isExpandedLoaded = true;
            if (result.key === "offshoreLeaks") {
                let id = result?.api_all_data["node_id"];
                let query_id;
                if (this.isAllRunQueries) {
                    query_id = result?.api_all_data?.query_id;
                } else {
                    query_id = result?.api_all_data?.query_id;
                }
                let payload = {
                    entity_id: id,
                    offset: 0,
                    limit: 100,
                    case_id: this.case_id,
                    query_id: query_id,
                    doc_id: result.api_all_data.doc_id,
                };
                if (id) {
                    try {
                        const {data} = await axios.post(`/adverse-media/icij-links`, payload);
                        data.data = data.data.map((item, index) => ({...item, index: index}));
                        const nodeTypes = uniqBy(data.data, "doc.data_type");
                        let nodes = [];
                        nodes = groupBy(data.data, "doc.data_type");
                        // nodes = mapValues(groupBy(data.data, "doc.data_type"), (nodelist) => nodelist.map((node) => omit(node, "doc.data_type")));
                        result.api_all_data = {
                            ...result.api_all_data,
                            ...{nodes},
                            ...{nodeTypes},
                        };
                    } catch (error) {}
                }
            }
            let detailsExpanded = true;
            if (result.detailsExpanded === false) detailsExpanded = false;

            if (detailsExpanded) {
                this.selectedResultCard = index;
                if (isOldVersion) {
                    this.timeline[index] = result;
                    this.isOldVersionSelected = true;
                } else {
                    this.adverseResults[index] = result;
                    this.isOldVersionSelected = false;
                }
                this.expandedResultCard = result;
                this.isResultExpanded = true;
                this.isComparisonModeOn = false;
                this.$emit("searchResult", {data: this.adverseResults, source: "offshoreleaks", searchType: "name"});
                if (result.expanded == undefined) result.expanded = true;
                else {
                    result.expanded = !result.expanded;
                }
                this.$forceUpdate();
                const resultContainer = document.getElementById(`result-expanded`);
                if (resultContainer) {
                    resultContainer.scrollTo({
                        top: 1,
                        behavior: "smooth",
                    });
                    // document.getElementById(`result-${index}`).scrollIntoViewIfNeeded(true);
                }
                this.isExpandedLoaded = false;
            } else {
                this.isComparisonModeOn = true;
                this.updateComparisonReferences(result);
            }
        },

        /**
         * @function
         * @name handleChangeCheck
         * @params {e} - pointer event for Complyadvantage checks input checkbox (adverse / peps/ sanction)
         */
        handleChangeCheck(e) {
            if (this.checks.includes(e.target.value)) {
                const filterCheck = this.checks.filter((el) => el !== e.target.value);
                this.checks = filterCheck;
            } else {
                this.checks.push(e.target.value);
            }
        },

        /**
         * @function
         * @name handleSourceChange
         * @params {e} - pointer event for source input checkbox (offshoreleaks)
         */
        handleSourceChange(e) {
            if (this.sources.includes(e.target.value)) {
                const filterSource = this.sources.filter((el) => el !== e.target.value);
                this.sources = filterSource;
            } else {
                this.sources.push(e.target.value);
            }

            for (let source in this.allSources) {
                if (this.allSources[source].value === e.target.value) {
                    this.allSources[source].isChecked = !this.allSources[source].isChecked;
                    break;
                }
            }
            this.entityType.props.options = this.entityTypeOptions;
        },

        /**
         * @function
         * @name handleSourceExpand
         * @params {source, value} - source & value
         */
        handleSourceExpand(source, value, toggle) {
            if (toggle) this.allSources[source].isChecked = !this.allSources[source].isChecked;
            if (this.sources.includes(value)) {
                const filterSource = this.sources.filter((el) => el !== value);
                this.sources = filterSource;
            } else {
                this.sources.push(value);
            }
            this.entityType.props.options = this.entityTypeOptions;
        },

        /**
         * @function
         * @name updateSource
         * @params {e} - pointer event for source input checkbox
         */
        updateSource(e, source) {
            if (e.target.checked && this.allSources[source].filters && this.allSources[source].filters.length) {
                this.allSources[source].isModalVisible = true;
            } else {
                this.handleSourceChange(e);
            }
            // this.manualSearch();
        },

        /**
         * @function
         * @name applyFilter - apply all filters
         */
        applyFilters(source, value) {
            if (!this.sources.includes(value)) this.sources.push(value);
            this.entityType.props.options = this.entityTypeOptions;
        },

        /**
         * @function
         * @name closeFilter - close filters popup
         */
        closeFilter(source, value) {
            if (this.allSources[source].isChecked && !this.sources.includes(value)) this.allSources[source].isChecked = false;
        },

        /**
         * @function
         * @name filterData - filter Data as per the filters applied
         */
        async filterData() {
            let data = cloneDeep(this.adverseResults);
            let searchKeyword = this.searchQuery.toLowerCase() ?? "";

            if (this.sourceType?.length) {
                data = data.filter((adv) => {
                    return this.sourceType.some((source) => adv?.key === source.name);
                });
            }

            if (this.filterEntityType?.length) {
                data = data.filter((adv) => {
                    return this.filterEntityType.some((type) => adv?.entity_type?.toLowerCase() === type.value);
                });

                if (this.filterEntityType.some((type) => type.source === "offshoreLeaks")) {
                    this.icij_entity_type = this.filterEntityType?.filter((el) => el.source === "offshoreLeaks")?.map((el) => el?.value);

                    const headers = {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                        "x-refresh": false,
                    };
                    let sourcesCallBody = {
                        name: this.searchKeyword,
                        search_mode: this.searchMode.value.value,
                        icij_entity_type: this.icij_entity_type,
                        sources: ["offshoreleaks"],
                        age: this.isRangeEnalbed ? this.age.map((ag) => parseInt(ag)) : [],
                        limit: 100,
                        page: 1,
                    };

                    const {data: sourcesCall} = await axios.post("/adverse-media/search/sources", sourcesCallBody, {headers});
                    this.request_id = sourcesCall.request_id;
                    this.redirectTo();
                }
            }

            if (this.filterTypes?.length) {
                data = data.filter(
                    (adv) => {
                        return this.filterTypes.some((type) => adv?.types.includes(type.value) || adv?.types === type.value.toLowerCase() || adv?.types.includes(type.value.toLowerCase()));
                    }
                    // adv?.types
                    //     .split(",")
                    //     .map((el) => el.toLowerCase())
                    //     .includes(this.filterTypes.label.toLowerCase())
                );
            }

            if (this.filterJurisdiction?.length) {
                data = data.filter((adv) => {
                    if (adv.key === "offshoreLeaks") {
                        return this.filterJurisdiction?.some((jurisdiction) => adv?.jurisdiction.toLowerCase() === jurisdiction.toLowerCase() || adv?.jurisdiction.toLowerCase().includes(jurisdiction) || adv?.jurisdiction.toLowerCase().includes(jurisdiction.toLowerCase()));
                    }
                });
            }
            this.adverseFilteredResults = data.filter((ele) => ele?.name?.toLowerCase().includes(searchKeyword));
            let allFilters = ["filterEntityType", "sourceType", "filterTypes", "filterJurisdiction", "filters.saved", "filters.monitoring"];

            let that = this;
            this.allFilters = [];
            for (let filter in allFilters) {
                let filterValues = get(that, allFilters[filter]);
                if (allFilters[filter].includes(".")) {
                    filterValues = filterValues === "all" ? [] : [filterValues];
                }
                if (this.isEnabledApplyButton) {
                    for (const value in filterValues) {
                        this.allFilters.push({
                            name: allFilters[filter],
                            value: filter === "sourceType" ? filterValues[value].name : filter === "filterTypes" ? filterValues[value].value : filterValues[value],
                        });
                    }
                }
            }
            this.appliedFilterCount = 0;
            if (this.isFilterApplied) {
                if (this.filters.saved !== "all") this.appliedFilterCount += 1;
                if (this.filters.monitoring !== "all") this.appliedFilterCount += 1;
                if (this.searchQuery) this.appliedFilterCount += 1;
                if (this.sourceType?.length) this.appliedFilterCount += 1;
                if (this.filterEntityType?.length) this.appliedFilterCount += 1;
                if (this.filterTypes?.length) this.appliedFilterCount += 1;
                if (this.filterJurisdiction?.length) this.appliedFilterCount += 1;
            }

            // this.page_number = 1;
            this.$forceUpdate();
        },

        async handleBulkMonitoring() {
            // const monitoringStatus = event.target.checked;
            this.bulkMonitor = true;
            this.isBulkMonntoringLoading = true;
            this.isBulkMonitorOn = !this.isBulkMonitorOn;
            let requestBody = {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: {
                    query_id: this.getSelectedToolQuery.query_id,
                    query_string: this.getSelectedToolQuery.query_string,
                    search_string: this.getSelectedToolQuery.search_string,
                },
                frequency: this.$props.frequency,
                status: this.isBulkMonitorOn ? "enabled" : "disabled",
            };
            try {
                const response = await mainAxios.post("/data-monitoring/bulk-enable", requestBody);
                if (response.status) {
                    this.bulkMonitor = false;
                    this.$toast.success("Bulk monitoring updated successfully");
                    // let start_count = this.items_count * (this.page_number - 1);
                    // let end_count = this.page_number * this.items_count;
                    let details = this.adverseResults;
                    details?.map((el) => {
                        el.api_all_data.monitoring_status = this.isBulkMonitorOn;
                    });
                    this.adverseResults = cloneDeep(details);
                    this.filterData();
                    this.$forceUpdate();
                }
            } catch (error) {
                this.$toast.error(error.message);
            }
            this.isBulkMonntoringLoading = false;
        },

        /**
         * @function
         * @name redirectTo - function to update input filters data to the route
         */
        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query: query}).catch(() => {});
        },

        async resetQueryFilter() {
            const filters = {
                monitoring: "all",
                saved: "all",
            };
            this.setToolsFilterData(filters);
            await this.getToolsStatus();
        },

        async manualSearch(refreshFlag) {
            this.triggerRoute = false;
            this.search(refreshFlag);
        },

        /**
         * When tab is monitoring
         * Fetch monitoring results
         * @param {array} sources: []
         */
        async monitoringSearch(sources) {
            this.showResult = true;
            this.startLoader("nameSearch");
            if (this.limitPag == 1) {
                this.offset = 0;
                this.adverseResults = [];
            }
            this.searchedClicked = true;
            //To navigate to a different URL, according to the selected query
            // this.redirectTo();
            if (this.limitPag > 1) {
                this.offset = Math.ceil((this.limitPag - 1) * 100);
            }
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery, this.offset);
            this.latestVersionTotalResults = values.totalResults;
            if (values?.values?.length > 0) {
                let offshore_leaks = this.getMappingValue(
                    values?.values?.filter((val) => val.doc_source === "offshoreleaks"),
                    "offshoreLeaks",
                    OFFSHORELEAKS_TOOL_MAPPING
                );
                this.adverseResults = [...this.adverseResults, ...offshore_leaks];
                this.adverseResults = this.adverseResults.map((ele, index) => ({...ele, curr_index: index}));
            }
            this.resetLoader("nameSearch");
            this.$emit("getInitialQueryCount");
        },

        /**
         * @function
         * @name search - function calls to perform search on the input filters
         *              - calls sources API to create a search query
         *              - calls search APIs on the selected sources
         */
        async search(refreshFlag) {
            // get monitoring change
            // let start = this.selectedItem.length;
            // if (this.currentQuery >= 0) start = this.currentQuery;
            this.monitoringStatus = false;
            this.currentQuery = -1;
            this.closeExpandedResult();
            this.adverseFilteredResults = [];
            this.isOldVersionSelected = false;
            this.timeline = [];
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });
            this.allQueriesParams = null;
            this.isResultExpanded = false;
            if (this.limitpag == 1) {
                this.adverseResults = [];
                this.offsetPag = 0;
                this.page_number = 1;
                this.adverseFilteredResults = [];
            }

            for (let i = 0; i < this.selectedItem.length; i++) {
                this.searchKeyword = this.selectedItem[0].value ?? "";
                this.page_number = 1;
                this.searchItem = this.searchKeyword;
                // const selCountries = this.country.value.length > 0 ? this.country.value.map((el) => el.value).sort((a, b) => a.localeCompare(b)) : [];

                if (!this.isSourceAuth("")) {
                    this.allSources["offshoreLeaks"].isChecked = false;
                    this.sources = this.sources.filter((e) => e !== "offshoreleaks");
                }
                // if (this.isSourceAuth('world_check')) this.allSources["worldCheck"].isChecked = this.sources.includes("worldcheck");
                // if (this.isSourceAuth('dow_jones'))
                // get monitoring change //
                if (this.$route.name === "monitoring") {
                    await this.monitoringSearch(this.sources, "company");
                    this.filterData();
                    return;
                }

                if ((i === 0 && this.searchKeyword) || this.selectedItem[i]?.value || this.isAllRunQueries) {
                    if (!this.sources.length && !this.isAllRunQueries) {
                        this.$toast.error("Please Select a Source");
                        return;
                    }
                    this.sources = this.sources.sort((a, b) => a.localeCompare(b));
                    this.searchedClicked = true;
                    if (i === 0) {
                        this.startLoader("nameSearch");
                        this.isLoading = true;
                    }
                    this.currentQuery = i;
                    this.$emit("searched", {data: {name: i === 0 ? this.searchKeyword : this.selectedItem[i].value}, source: "offshoreleaks"});
                    const headers = {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                        "x-refresh": refreshFlag || false,
                    };
                    let sourcesCallBody = {
                        name: i === 0 ? this.searchKeyword : this.selectedItem[i]?.value,
                        sources: this.sources.map((ele) => ele.toLowerCase()).sort((a, b) => a.localeCompare(b)),
                        age: this.isRangeEnalbed ? this.age.map((ag) => parseInt(ag)) : [],
                    };

                    if (this.allSources["offshoreLeaks"].isChecked) {
                        sourcesCallBody.search_mode = this.searchMode.value.value;
                        sourcesCallBody.icij_entity_type = this.icij_entity_type;
                        sourcesCallBody.limit = 100;
                        sourcesCallBody.page = 1;
                    }

                    // sourcesCallBody.age=this.age
                    const {data: sourcesCall} = await axios.post("/adverse-media/search/sources", sourcesCallBody, {headers});
                    this.request_id = sourcesCall.request_id;
                    this.redirectTo();
                    this.showResult = true;
                    this.showSearch = false;
                    let offshoreLeaks;

                    if (this.allSources["offshoreLeaks"].isChecked) {
                        const body = {
                            name: i === 0 ? this.searchKeyword : this.selectedItem[i]?.value,
                            limit: 100,
                            offset: 1,
                            entity_type: this.icij_entity_type,
                            request_id: sourcesCall.request_id,
                            age: this.isRangeEnalbed ? this.age : [],
                            search_mode: this.searchMode.value.value,
                            sort_by: this.sortKeysMapping[this.order_by?.value]["offshoreLeaks"] || "",
                            sort_order: "desc",
                        };
                        if (this.nerFilters) body.output_filters = this.nerFilters;
                        if (this.filters.saved !== "all" && this.isFilterApplied) {
                            body.saved = this.filters.saved === "saved";
                        }
                        if (this.filters.monitoring !== "all" && this.isFilterApplied) {
                            body.monitoring_status = this.filters.monitoring === "monitoring";
                        }
                        offshoreLeaks = axios.post("/adverse-media/icij-search", body, {
                            headers,
                        });
                    }
                    this.$emit("searched", {data: {name: i === 0 ? this.searchKeyword : this.selectedItem[i]?.value}, source: "offshoreleaks"});
                    await this.setSearchedUser(i === 0 ? this.searchKeyword : this.selectedItem[i]?.value);
                    if (offshoreLeaks && i === 0) {
                        this.adverseResults = [];
                        Promise.allSettled([offshoreLeaks])
                            .then(async (results) => {
                                let values = results.map((result) => result.value);
                                ///// offshoreLeaks //////
                                if (values[0]?.data?.data?.length > 0) {
                                    // const result = values[1].data?.data.map((e) => ({ ...e, source: "offshoreLeaks" }));
                                    const map_result = values[0]?.data?.data.map((e) => ({...e, off_status: e.status}));
                                    const result = this.getMappingValue(map_result, "offshoreLeaks", OFFSHORELEAKS_TOOL_MAPPING);
                                    this.adverseResults = [...this.adverseResults, ...result];
                                }
                                if (values[0]?.data?.category?.length > 0) {
                                    this.offshore_entities = values[0]?.data?.category.map((e) => ({
                                        entity_type: e.key,
                                    }));
                                }
                                this.adverseResults = this.adverseResults.map((ele, index) => ({...ele, curr_index: index}));
                                this.filterData();
                                this.adverseFilteredResults = this.adverseFilteredResults.map((result) => {
                                    return result;
                                });

                                if (results[0]?.status === "rejected" || values[0]?.status === 500) {
                                    this.showResult = true;
                                    this.isLoading = false;
                                    this.$toast.error(`OffshoreLeaks: Something went wrong. Please try again.`);
                                }

                                this.sortResults();
                                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "adverse-media-tool"});
                                this.showResult = true;
                                this.triggerRoute = true;
                                this.isLoading = false;
                                this.$emit("searchResult", {data: this.adverseResults, source: "offshoreleaks", searchType: "name"});
                                this.resetLoader("nameSearch");
                                this.$emit("getInitialQueryCount");
                            })
                            .catch((error) => {
                                this.$toast.error(`Something went wrong. Please try again.`);
                                this.showResult = true;
                                this.isLoading = false;
                                this.resetLoader("nameSearch");
                            });
                    }
                } else if (i === 0) {
                    this.$toast.error("Please enter a valid input");
                }
            }
        },

        /**
         * @function
         * @name setSearchedUser - run input query on tool and then handle green tick in the dropdown
         * @params {searchKeyword} - input search string to be searched
         */
        async setSearchedUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "offshoreleaks",
                val_type: "name",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`nameSearches`][0]?.categories.find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`nameSearches`][0]?.categories.push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        /**
         * @function
         * @name redirectToQuery
         * @params {object} - dataRes - search filters data from the route to be autofill in the input fiels and filters
         */

        redirectToQuery(dataRes) {
            this.items_count = 10;
            this.offset = 0;
            this.limitPag = 1;
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                let {name, sources, entity_type, search_mode} = selected_query?.query;

                if (name || sources || entity_type || search_mode) {
                    if (name) {
                        this.searchKeyword = name;
                        this.selectedItem = [
                            {
                                value: name,
                            },
                        ];
                        this.selectVal = [name];
                    }
                    if (sources) {
                        this.allSources["offshoreLeaks"].isChecked = this.sources.includes("offshoreleaks");
                    }

                    if (search_mode) {
                        this.searchMode.value.value = search_mode;
                    }

                    let entityFind = this.entityType?.props.options?.find((ele) => ele.value === entity_type);
                    this.entityType.value = entityFind ? entityFind : {name: "All", value: ""};

                    if (!dataRes) this.search();
                } else if (this.searchedClicked) {
                    if (!dataRes) this.search();
                    else this.redirectTo();
                }
            } else {
                this.redirectTo();
            }
        },

        loadMoreCompanies(pageNumber) {
            this.page_number = pageNumber;
        },

        /**
         * @function
         * @name fetchAllJuridictions - get all jusridiction list and map in the jurisdiction dropdown
         */

        async fetchAllJuridictions() {
            const {data} = await axios.get("company/search/jurisdiction");
            const jurisdiction = data.data.map((el) => ({
                name: el.label,
                value: el.value,
            }));
            this.countriesOptions = [
                {
                    group: "Countries",
                    categories: jurisdiction,
                },
            ];
            // this.country.props.options = this.countriesOptions;
        },

        onSelectOption(option) {
            this.order_by = option;
            this.manualSearch();
        },

        /**
         * @function
         * @name resetFilter - reset all search inputs and filters
         */
        resetFilter() {
            // this.entityType.value = { name: "All", value: "" };
            // this.fuzzinessLevel.value = 0.7;
            // this.yearOfBirth.value = "";
            // this.country.value = [];
            this.isFilterApplied = false;
            this.peps = true;
            this.adverseMedia = true;
            this.sanction = true;
            this.sources = [];
            for (let source in this.allSources) {
                this.allSources[source].isChecked = false;
            }
            this.order_by = {
                name: "Updated Date",
                value: "updated_at",
            };
            this.loaderController = {
                nameSearch: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            };
            this.request_id = null;
            this.selectedItem = [];
            this.searchKeyword = "";
            this.entityCount = 1;
            this.queries = [];
            this.selectVal = [""];
            this.showResult = false;
            this.showFiltersPanel = false;
            this.adverseResults = [];
            this.adverseFilteredResults = [];
            this.searchQuery = "";
            this.sourceType = [];
            this.filterEntityType = [];
            this.filterTypes = [];
            this.filterJurisdiction = [];
            this.offshore_entities = [];
            this.icij_entity_type = [];
            this.items_count = 10;
            this.request_id = "";
            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;
            this.limitPag = 1;
            this.isOldVersionSelected = false;
            this.timeline = [];
            this.$emit("updateMonitoringCascadingTimeline", {
                timeline: this.timeline,
                meta_data: null,
            });
            this.redirectTo();
            this.$forceUpdate();
        },

        closeExpandedResult() {
            this.isResultExpanded = false;
            this.selectedResultCard = null;
            this.isOldVersionSelected = false;
        },
        resetPage() {
            this.page_number = 1;
            this.closeExpandedResult();
        },
        async handlePageChange(page_number) {
            if (this.$route.name === "monitoring" && this.getTotalPages - 1 <= this.page_number && this.adverseResults.length >= this.items_count * this.page_number && this.latestVersionTotalResults != this.adverseResults.length) {
                this.offsetPag += 1;
                this.limitPag += 1;
                this.manualSearch();
            }
            this.loadMoreCompanies(page_number);
            this.closeExpandedResult();
            this.scrollToTop();
            if (this.isAllRunQueries) {
                if (this.getTotalPages - 1 <= this.page_number && this.adverseResults.length >= this.items_count * this.page_number) {
                    await this.onRunAllQueries(null, "adverseResults");
                }
            }
        },
        scrollToTop() {
            document.querySelector("section#result-list").scrollTop = 0;
        },

        /**
         * @function
         * @name clearFilters - function to clear output filters
         */
        async clearFilters() {
            this.page_number = 1;
            this.currentQuery = -1;
            this.sourceType = [];
            this.searchQuery = "";
            this.filterEntityType = [];
            this.isFilterApplied = false;
            this.filterTypes = [];
            this.filterJurisdiction = [];
            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.offshore_entities = [];
            this.icij_entity_type = [];
            this.closeExpandedResult();
            await this.onApplyFilter();
            this.sortResults();
        },

        /**
         * @function
         * @name addEntity - function to add a new entity
         */
        addEntity() {
            this.newEntity = {
                name: "",
            };
            this.queries.push(this.newEntity);
            this.entityCount += 1;
            this.newEntity = null;
        },
        getClipboardData(e) {
            e.stopPropagation();
            e.preventDefault();
            e.cancelBubble = true;
            const clipboardData = e.clipboardData?.getData("Text");
            let newclipboardData = clipboardData.replace(/,/g, "\n");
            this.addTag(newclipboardData);
            // this.selectedItem = newTag?.split(/\t/)[0]?.split('\n')
        },
        addTag(newTag, eventindex) {
            let newTags = [];
            if (newTag.includes(",")) {
                newTags = newTag.split(",")?.filter((el) => el && (el !== " " || el !== ""));
            } else {
                newTags = [
                    ...newTags,
                    ...newTag
                        ?.split(/\t/)[0]
                        ?.split("\n")
                        ?.filter((el) => el && (el !== " " || el !== "")),
                ];
            }
            let newTagsData = newTags.filter(function(item) {
                return item.trim() != "";
            });
            newTagsData.forEach((tg) => {
                if (tg !== "" && tg !== " ") {
                    tg = tg?.trim();
                    const tag = {
                        name: tg,
                        value: tg?.trim(),
                        code: tg.substring(0, 2) + Math.floor(Math.random() * 10000000),
                    };
                    let index = this.selectedItem.findIndex((item) => item?.value === tg);
                    let secIndex = this.nameSearches[0]?.categories.findIndex((item) => item?.value === tg);

                    if (index > -1) {
                    } else {
                        this.selectedItem.push(tag);
                    }
                    if (secIndex > -1) {
                        if (this.nameSearches[0]?.categories[secIndex].name !== this.nameSearches[0]?.categories[secIndex].value && (this.nameSearches[0]?.categories[secIndex].visited || this.nameSearches[0]?.categories[secIndex].visited == false)) {
                            const tag1 = {
                                name: tg,
                                value: tg?.trim(),
                                code: tg.substring(0, 2) + Math.floor(Math.random() * 10000000),
                            };
                            this.nameSearches[0]?.categories.push(tag1);
                            let selectedIndex = this.selectedItem.findIndex((el) => el.name === tag.name);
                            if (selectedIndex) {
                                this.selectedItem.splice(selectedIndex, 1);
                                this.selectedItem.splice(eventindex, 0, tag1);
                            }
                        }
                    } else {
                        this.nameSearches[0]?.categories.push(tag);
                    }

                    this.searchKeyword = this.selectedItem[0].value ?? "";
                    // this.nameSearches[0].categories[secIndex].value = this.nameSearches[0]?.categories[secIndex].name
                }
            });
        },

        /**
         * @function
         * @name removeEntity - function to remove a particular entity
         * @params {Number} i - index of entity to be removed
         */
        removeEntity(i) {
            if (i < 0) {
                if (this.queries?.length) {
                    this.selectedItem = this.queries[0].name;
                    this.searchKeyword = this.selectedItem.value;
                    this.queries.splice(0, 1);
                    this.entityCount -= 1;
                    this.newEntity = "";
                    this.selectVal.splice(0, 1);
                } else {
                    this.selectedItem = null;
                    this.searchKeyword = "";
                    this.selectVal[0] = "";
                }
            } else {
                this.selectVal.splice(i + 1, 1);
                this.queries.splice(i, 1);
                this.entityCount -= 1;
                this.newEntity = "";
            }
        },
        onSelect({value}, idx) {
            this.selectVal[idx - 1] = value;
            this.$refs["analysis-tool-input"][idx - 1].search = value;
        },
        close(idx) {
            if (idx === 1) {
                this.searchKeyword = this.selectVal[0];
                this.selectedItem = {
                    value: this.selectVal[0],
                };
                this.$refs["analysis-tool-input"][0].search = this.selectVal[0];
            } else {
                if (!this.queries[idx - 2].name) {
                    this.queries[idx - 2].name = {value: this.selectVal[idx - 1]};
                } else {
                    this.queries[idx - 2].name.value = this.selectVal[idx - 1];
                }
                this.$refs["analysis-tool-input"][idx - 1].search = this.selectVal[idx - 1];
            }
        },
        handleChange(event, idx) {
            // if (event != this.selectVal) {
            //     this.searchClicked = false;
            // }
            this.selectVal[idx - 1] = event;
        },

        /**
         * @function
         * @name cancelUpload - function to cancel the file uploader
         */

        cancelUpload() {
            this.$refs["uploadBtn"].cancelUpload();
        },
        resultListStyles() {
            // isResultExpanded && 'width:40% !important;' && isMonitoringTab ? 'max-height: calc(100% - 202px)' : 'max-height: calc(100% - 128px)'
            let styles = "";
            if (this.isQueryPanelExpanded && this.isResultExpanded) styles += "width:30% !important ;";
            else if (this.isResultExpanded && !this.isQueryPanelExpanded) styles += "width:40% !important;";
            return styles;
        },
        // isResultExpanded ? 'width:60% !important;' : $route.name == 'monitoring' ? ' max-height: calc(100% - 240px)!important; ' : ' max-height: calc(100% - 140px)!important;'
        getStyles() {
            let styles = " ";
            if (this.isQueryPanelExpanded && this.isResultExpanded) styles += "width:70% !important ;";
            else if (this.isResultExpanded && !this.isQueryPanelExpanded) styles += "width:60% !important;";
            // if (this.$route.name == "monitoring") styles += " max-height: 100% - 100px)!important; ";
            // else styles += " max-height: 100% !important; ";
            return styles;
        },

        /**
         * @function
         * @name handleMarkAsAllDone
         * @params {e} - pointer event for Monitoring - mark all done input checkbox
         */
        async handleMarkAsAllDone(event) {
            const visited = event.target.checked;
            let doc_id = [];
            let payload;
            this.adverseResultsList.slice(this.items_count * (this.page_number - 1), this.page_number * this.items_count).forEach((advRes, index) => {
                // doc_id.push(advRes.api_all_data.monitoring_id);
                let sources = [];
                sources.push(advRes.api_all_data.doc_source),
                    (payload = {
                        doc_id: advRes.api_all_data.monitoring_id,
                        sources: sources,
                        cascaded: advRes.api_all_data?.history_count ? true : false,
                    });
                doc_id.push(payload);
                this.adverseResults = this.adverseResults.map((res) => {
                    let obj = res;
                    if (advRes.curr_index === res.curr_index) {
                        obj.api_all_data.changes_viewed = visited;
                    }
                    return obj;
                });
            });
            if (this.timeline.length > 0) {
                this.timeline = this.timeline.map((res) => {
                    let obj = res;
                    obj.api_all_data.changes_viewed = visited;
                    return obj;
                });
            }
            this.filterData();
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                card_info: doc_id,
                case_id: this.getCaseId,
                visited,
                // query_id: this.getSelectedToolQuery.query_id,
            });
        },

        /**
         * @function
         * @name isSourceAuth
         * @params {String} src - selected source (offshoreleaks)
         */
        isSourceAuth(src) {
            if (src) return this.getPermissions.includes(`${src}__adverse_pep/sacntion`) || this.getPermissions.includes(`${src}__adverse_pep_sacntion`);
            else return true;
        },

        /**
         * @function
         * @name isSourceSpecificFilter
         * @params {String} src - selected source (offshoreleaks)
         */
        isSourceSpecificFilter(src) {
            return this.allSources[src].filters && this.allSources[src].filters.length && this.allSources[src].filters.filter((filter) => filter.isSourceSpecific)?.length;
        },

        /**
         * @function
         * @name handleFilterApplied
         * @params {Object} filter - selected filter
         */
        async handleFilterApplied(filter) {
            let match = -1;
            switch (filter.name) {
                case "sourceType":
                    match = this[filter.name].findIndex((el) => el.name === filter.value.name);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filterTypes":
                    match = this[filter.name].findIndex((el) => el.label === filter.value.label);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filterJurisdiction":
                    match = this[filter.name].findIndex((el) => el === filter.value);
                    if (match > -1) this[filter.name].splice(match, 1);
                    break;
                case "filters.monitoring":
                    this.filters.monitoring = "all";
                    break;
                case "filters.saved":
                    this.filters.saved = "all";
                    break;
            }
            this.page_number = 1;
            await this.onApplyFilter();
            this.sortResults();
        },

        /**
         * @function
         * @name parseValue
         * @params {Object} value - selected filter value
         */
        parseValue(value) {
            return typeof value === "string" ? value : value.label || value.name || value.value;
        },

        /**
         * @function
         * @name toggleAllSources - toggle selection of all sources
         */
        toggleAllSources() {
            if (!this.allSourcesChecked) {
                for (let source in this.allSources) this.allSources[source].isChecked = true;
                this.sources = ["offshoreleaks"];
            } else {
                for (let source in this.allSources) this.allSources[source].isChecked = false;
                this.sources = [];
            }
        },

        /**
         * @function
         * @name sortResults
         */
        sortResults() {
            this.adverseFilteredResults = [
                ...this.adverseFilteredResults
                    .filter((el) => el[this.order_by.value])
                    ?.sort((a, b) => {
                        let diff = new Date(a[this.order_by.value]) - new Date(b[this.order_by.value]);
                        if (diff > 0) return -1;
                        else return 1;
                    }),
                ...this.adverseFilteredResults.filter((el) => !el[this.order_by.value]),
            ];
        },
        async onApplyFilter() {
            this.isFilterApplied = true;
            const {monitoring, saved} = this.prevAppliedFilters;
            const {monitoring: fil_monitoring, saved: fil_saved} = this.filters;

            if (fil_monitoring != monitoring || fil_saved != saved) {
                if (this.isAllRunQueries) {
                    this.offsetPag = 0;
                    this.limitPag = 1;
                    this.isNextPag = true;
                    this.adverseResults = [];
                    await this.onRunAllQueries(null, "adverseResults");
                } else await this.search();
            }
            this.filterData();
            this.setPrevFilters();
            this.$forceUpdate();
            // await this.getToolsStatus();
        },
        async onBackToSearch() {
            this.order_by = {
                name: "Updated Date",
                value: "updated_at",
            };
            this.request_id = null;
            this.entityCount = 1;
            this.queries = [];
            this.selectVal = [""];
            this.showResult = false;
            this.showFiltersPanel = false;
            this.isFilterApplied = false;
            this.searchQuery = "";
            this.sourceType = [];
            this.filterEntityType = [];
            this.filterTypes = [];
            this.filterJurisdiction = [];
            this.items_count = 10;

            this.filters = {
                saved: "all",
                monitoring: "all",
            };
            this.isAllRunQueries = false;
            this.allQueriesParams = null;
            this.offsetPag = 0;
            this.limitPag = 1;
            this.isExpandedLoaded = false;
            this.icij_entity_type = [];
            this.resetLoader("nameSearch");
            this.setPrevFilters(this.filters);
            this.setToolsFilterData(this.filters);
            await this.getToolsStatus();
            this.$router.push({path: this.$route.path}).catch((err) => {
                console.error(err);
            });
            this.$forceUpdate();
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
        setPrevFilters() {
            let {monitoring, saved} = this.filters;
            this.prevAppliedFilters = {
                monitoring,
                saved,
                sourceType: this.sourceType,
                searchQuery: this.searchQuery,
                filterEntityType: this.filterEntityType,
                filterTypes: this.filterTypes,
                filterJurisdiction: this.filterJurisdiction,
            };
        },
        async onPerPageChange() {
            this.page_number = 1;
            if (this.adverseResults.length <= this.items_count) {
                if (this.isAllRunQueries) {
                    this.adverseResults = [];
                    this.offsetPag = 0;
                    this.limitPag = 1;
                    await this.onRunAllQueries(null, "adverseResults");
                    if (this.adverseResults.length <= this.items_count) {
                        this.offsetPag = 1;
                        this.limitPag = 2;
                        await this.onRunAllQueries(null, "adverseResults");
                    }
                } else {
                    this.manualSearch();
                    if (this.latestVersionTotalResults > 100) {
                        this.offsetPag += 1;
                        this.limitPag += 1;
                        await this.manualSearch();
                    }
                }
                this.scrollToTop();
            }
        },

        async handleSearch() {
            if (!this.isSearchBtnDisabled) {
                let globalFilters = false;
                globalFilters = await this.checkGlobalFilters();
                if (!globalFilters) {
                    await this.manualSearch();
                    await this.resetQueryFilter();
                }
            }
        },

        async checkGlobalFilters() {
            if (this.nerFilters) {
                try {
                    const confirm = await this.$refs["modal-confirm"].show({
                        title: "Reset Global Filters",
                        message: "There are some Global Filters applied. Are you sure you want to reset all Global Filters ?",
                        autoclose: false,
                    });
                    if (confirm) {
                        this.$emit("handleNerClear");
                        this.$refs["modal-confirm"].close();
                        return false;
                    } else return true;
                } catch (error) {
                    this.$refs["modal-confirm"].close();
                }
            } else return false;
        },

        async showMonitoringHistory(data) {
            const payload = data.payload;
            let source = [];
            if (this.sources?.length > 0) {
                source = this.sources;
            }
            if (payload.monitoringHistoryIndex) {
                this.timeline = [];

                const values = await monitoringHistoryData(payload, source);
                if (values?.length > 0) {
                    let offshore_leaks = this.getMappingValue(
                        values.filter((val) => val.doc_source === "offshoreleaks"),
                        "offshoreLeaks",
                        OFFSHORELEAKS_TOOL_MAPPING
                    );
                    this.timeline = [...this.timeline, ...offshore_leaks];
                    this.timeline = this.timeline.map((ele, index) => ({...ele, curr_index: index, isOldVersion: true}));
                }
                const parent = {
                    ...data.detail,
                    is_parent: true,
                };
                const meta_data = {};
                this.$emit("updateMonitoringCascadingTimeline", {
                    timeline: cloneDeep(this.timeline)
                        .reverse()
                        .concat([parent]),
                    meta_data: meta_data,
                });
            } else {
                this.timeline = [];
                this.$emit("updateMonitoringCascadingTimeline", {
                    timeline: this.timeline,
                    meta_data: null,
                });
            }
        },

        updateComparisonReferences(details) {
            if (this.isComparisonModeOn) {
                const isOldVersion = details.isOldVersion;
                if (isOldVersion) {
                    this.$emit("updateMonitoringCascadingTimeline", {
                        newData: details,
                    });
                } else {
                    const parent = {
                        ...details,
                        is_parent: true,
                    };
                    this.$emit("updateMonitoringCascadingTimeline", {
                        newData: parent,
                    });
                }
            }
        },
    },
};
